import React from 'react';
import {
  TextField,
  Button,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import theme from '../theme';
import GatsbyLink from 'gatsby-link';

const slugify = (text: string) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, ''); // Replace sp"aces with -
  // .replace(/[^\w\-]+/g, '') // Remove all non-word chars
  // .replace(/\-\-+/g, '-') // Replace multiple - with single -
  // .replace(/^-+/, '') // Trim - from start of text
  // .replace(/-+$/, ''); // Trim - from end of text
};

const Title = styled(Typography)`
  &&&& {
    margin-bottom: 10px;
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 25px;
    ${theme.breakpoints.down('sm')} {
      font-size: 18px;
      padding: 0;
    }
  }
`;

@observer
class LoginPage extends React.Component {
  @observable
  public slug: string = '';

  @observable
  public loading: boolean = false;

  @observable
  public error: boolean = false;

  @action
  public handleSlugUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.slug = slugify(e.target.value);
  }

  @action
  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.loading = true;
    try {
      await axios.get(
        `${process.env.GATSBY_CAMPUS_ORG_API_URL!.replace(
          '{slug}',
          this.slug,
        )}/organizations/current`,
      );
      location.href = `https://${this.slug}.lfai.co/login`;
    } catch (e) {
      runInAction(() => {
        this.error = true;
        this.loading = false;
      });
    }
    return false;
  }

  public render() {
    return (
      <Layout isCreatePage>
        <form
          onSubmit={this.onSubmit}
          style={{
            margin: '0 auto',
            marginTop: '120px',
            display: 'flex',
            flexDirection: 'column',
            width: '400px',
            maxWidth: '100%',
          }}
        >
          <Title>Accédez à votre Campus</Title>
          <TextField
            margin="normal"
            variant="outlined"
            label="Nom de votre campus"
            value={this.slug}
            onChange={this.handleSlugUpdate}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">.lfai.co</InputAdornment>
              ),
            }}
            error={this.error && !this.loading}
            helperText={
              this.error && !this.loading
                ? 'Aucun Campus ne correspond à ce nom'
                : ''
            }
          />
          <Button
            style={{ marginTop: '15px' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.slug.length === 0}
          >
            Rejoindre
          </Button>
          <GatsbyLink
            to="/demo"
            style={{
              textAlign: 'center',
              color: theme.palette.grey[500],
              textDecoration: 'none',
              cursor: 'pointer',
              fontFamily: 'Roboto',
              fontSize: '15px',
              marginTop: '20px',
            }}
          >
            Je souhaite essayer gratuitement notre démonstrateur
          </GatsbyLink>
        </form>
      </Layout>
    );
  }
}
export default LoginPage;
